import React from 'react'

// PAGES
import SignIn from 'pages/SignIn/SignIn'

const App = () => {
  return (
    <SignIn/>
  )
}

export default App