// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let customTheme = createTheme({
  palette: {
    primary: {
      main: colors.orange,
    },
  },
  typography: {
    fontFamily: [
      'Open Sans', 'sans-serif',
    ].join(','),
  },
})

customTheme = responsiveFontSizes(customTheme) 

export default customTheme