import React, { useState, useEffect } from 'react'

// ASSETS
import LogoValid from 'assets/images/logos/valid_full_color.png'

// CUSTOM COMPONENTS
import CustomTextField from 'components/Customs/CustomTextField'

// MUIS
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

// OTP INPUT
import OtpInput from 'react-otp-input'

// STYLES
import useStyles from './signInUseStyles'

const SignIn = () => {
  const classes = useStyles()

  const initialResendTimeRemaining = 20

  const [ phoneNumber, setPhoneNumber ] = useState('')
  const [ isPhoneNumberSubmitted, setIsPhoneNumberSubmitted ] = useState(false)
  const [ otpNumber, setOtpNumber ] = useState('')
  const [ resendTimeRemaining, setResendTimeRemaining ] = useState(initialResendTimeRemaining)

  const handleChangePhoneNumberTextClick = () => {
    setPhoneNumber('')
    setIsPhoneNumberSubmitted(false)
    setResendTimeRemaining(initialResendTimeRemaining)
  }

  useEffect(() => {
    if(resendTimeRemaining > 0 && isPhoneNumberSubmitted){
      setTimeout(() => {
        setResendTimeRemaining(current => current - 1)
      }, 1000)
    }
  }, [resendTimeRemaining, isPhoneNumberSubmitted])

  return (
    <Box className={classes.pageRoot}>
      {/* VALID LOGO */}
      <Box
        component='img'
        src={LogoValid}
        alt=''
        className={classes.companyLogo}
      />

      {/* VERIFICATION FORM */}
      {!isPhoneNumberSubmitted &&
      <Box className={classes.form}>
        {/* TITLE */}
        <Typography
          variant='h6'
          className={classes.title}
        >
          Let's Get Started
        </Typography>

        {/* CAPTION */}
        <Typography
          variant='subtitle2'
          className={classes.caption}
        >
          We need your phone number verification to log in
        </Typography>

        {/* PHONE NUMBER */}
        <Box className={classes.phoneNumberContainer}>
          {/* COUNTRY */}
          <Box className={classes.countryContainer}>
            {/* ICON */}
            <Box
              component='img'
              src={`https://flagcdn.com/w20/id.png`}
              alt=''
              className={classes.countryIcon}
            />

            {/* TEXT */}
            <Typography
              variant='subtitle2'
              className={classes.countryText}
            >
              +62
            </Typography>
          </Box>

          {/* INPUT */}
          <CustomTextField
            variant='standard' 
            label='Phone Number'
            type='number'
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            className={classes.phoneNumberInput}
          />
        </Box>

        {/* AGREEMENT TEXT */}
        <Typography
          variant='subtitle2'
          className={classes.agreementText}
        >
          By clicking verify, you agree<br/>
          <Link
            className={classes.orangeText}
            href='/terms-and-condition'
          >
            Terms and Conditions of Service & Privacy Policy.
          </Link>
        </Typography>

        {/* VERIFICATION BUTTON */}
        <Button
          color='primary'
          className={classes.button}
          variant='contained'
          disabled={phoneNumber === '' ? true : false}
          onClick={() => setIsPhoneNumberSubmitted(true)}
        >
          Verification
        </Button>
      </Box>}

      {/* CONFIRMATION FORM */}
      {isPhoneNumberSubmitted &&
      <Box className={classes.form}>
        {/* TITLE */}
        <Typography
          variant='h6'
          className={classes.title}
        >
          Let's Get Started
        </Typography>

        {/* CAPTION */}
        <Typography
          variant='subtitle2'
          className={`${classes.caption} ${classes.marginBottom36}`}
        >
          {`Enter the OTP code we sent via SMS to your registered phone number +62${phoneNumber}`}
        </Typography>

        {/* OTP INPUT */}
        <OtpInput
          value={otpNumber}
          onChange={(otpValue) => setOtpNumber(otpValue)}
          numInputs={6}
          separator={<Box className={classes.otpSeparator}/>}
          inputStyle={classes.otpInput}
          shouldAutoFocus
          isInputNum
        />

        {/* RESEND TEXT */}
        <Typography
          variant='subtitle2'
          className={`${classes.caption} ${classes.marginBottom36} ${classes.marginTop36}`}
        >
          Haven't got the code yet?&nbsp;
          {resendTimeRemaining > 0 ?
          <span className={classes.blackText}>
            {`Resend in ${resendTimeRemaining} seconds`}
          </span> :
          <Link className={classes.orangeText}>
            Resend
          </Link>}
        </Typography>

        {/* WRONG NUMBER TEXT */}
        <Typography
          variant='subtitle2'
          className={`${classes.caption} ${classes.marginBottom36}`}
        >
          Wrong Number?&nbsp;
          <Link
            className={classes.orangeText}
            onClick={handleChangePhoneNumberTextClick}
          >
            Change Phone Number
          </Link>
        </Typography>

        {/* CONFIRMATION BUTTON */}
        <Button
          color='primary'
          className={classes.button}
          variant='contained'
          disabled={otpNumber.length < 6 ? true : false}
        >
          Confirmation
        </Button>
      </Box>}
    </Box>
  )
}

export default SignIn