// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  companyLogo: {
    height: 36,
    marginBottom: 40,
  },
  form: {
    width: 540,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: `-1px 10px 10px rgba(0, 0, 0, 0.05), 
    1px 12px 30px rgba(0, 0, 0, 0.1), 
    0px 2px 5px rgba(0, 0, 0, 0.1)`,
    padding: '50px 60px',
  },
  title: {
    fontWeight: 600,
    marginBottom: 16,
  },
  caption: {
    color: alpha(colors.black, 0.54),
    fontWeight: 400,
    textAlign: 'center',
  },
  phoneNumberContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    marginTop: 44,
    marginBottom: 48,
  },
  countryContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 76,
    height: 36,
    border: `1px solid ${alpha(colors.black, 0.1)}`,
    marginRight: 16,
  },
  countryIcon: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    marginRight: 4,
    filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15))',
  },
  countryText: {
    fontWeight: 600,
  },
  phoneNumberInput: {
    flex: 1,
  },
  agreementText: {
    color: alpha(colors.black, 0.54),
    textAlign: 'center',
    marginBottom: 48,
  },
  orangeText: {
    color: colors.orange,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  button: {
    width: '100%',
    height: 44,
    fontWeight: 600,
    color: colors.white,
    textTransform: 'none',
    backgroundColor: colors.orange,
    '&.Mui-disabled': {
      backgroundColor: alpha(colors.orange, 0.54),
      color: colors.white,
    },
  },
  otpSeparator: {
    marginRight: 8,
  },
  otpInput: {
    width: '48px !important',
    border: 'none',
    borderBottom: `1px solid ${alpha(colors.black, 0.1)}`,
    height: 48,
    fontSize: 20,
    fontWeight: 600,
  },
  blackText: {
    color: colors.black,
  },
  marginBottom36: {
    marginBottom: 36,
  },
  marginTop36: {
    marginTop: 36,
  },
}))

export default useStyles